var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "page-title-bar" }, [
        _c("i", { staticClass: "ico ico-system" }),
        _vm._v(_vm._s(_vm.$t("System License")))
      ]),
      _c("h3", { staticClass: "tit-group mt-10" }, [
        _vm._v(_vm._s(_vm.$t("License Information")))
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                staticClass: "form-input",
                attrs: {
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  label: _vm.Lavel_Active,
                  readonly: true
                },
                model: {
                  value: _vm.active,
                  callback: function($$v) {
                    _vm.active = $$v
                  },
                  expression: "active"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                staticClass: "form-input",
                attrs: {
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  label: _vm.Label_NumberOfStore,
                  readonly: true
                },
                model: {
                  value: _vm.numberOfStore,
                  callback: function($$v) {
                    _vm.numberOfStore = $$v
                  },
                  expression: "numberOfStore"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                staticClass: "form-input",
                attrs: {
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  label: _vm.Label_ServerType,
                  readonly: true
                },
                model: {
                  value: _vm.serverType,
                  callback: function($$v) {
                    _vm.serverType = $$v
                  },
                  expression: "serverType"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                staticClass: "form-input",
                attrs: {
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  label: _vm.Label_ActiveLayoutDesigner,
                  readonly: true
                },
                model: {
                  value: _vm.activeLayoutDesigner,
                  callback: function($$v) {
                    _vm.activeLayoutDesigner = $$v
                  },
                  expression: "activeLayoutDesigner"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                staticClass: "form-input",
                attrs: {
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  label: _vm.Label_LicenseId,
                  readonly: true
                },
                model: {
                  value: _vm.licenseId,
                  callback: function($$v) {
                    _vm.licenseId = $$v
                  },
                  expression: "licenseId"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                staticClass: "form-input",
                attrs: {
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  label: _vm.Label_ExpiryDate,
                  readonly: true
                },
                model: {
                  value: _vm.expiryDate,
                  callback: function($$v) {
                    _vm.expiryDate = $$v
                  },
                  expression: "expiryDate"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("h3", { staticClass: "tit-group mt-10" }, [
        _vm._v(_vm._s(_vm.$t("License Key")))
      ]),
      _c(
        "div",
        { staticClass: "licenseBox" },
        [
          _c("v-textarea", {
            ref: "encodedLicense",
            staticClass: "form-input",
            attrs: {
              outlined: "",
              dense: "",
              "hide-details": "",
              solo: "",
              placeholder: _vm.$t("Please input License Key"),
              height: "200",
              clearable: ""
            },
            model: {
              value: _vm.encodedLicense,
              callback: function($$v) {
                _vm.encodedLicense = $$v
              },
              expression: "encodedLicense"
            }
          }),
          _c(
            "v-btn",
            {
              staticClass: "btn ml-5",
              attrs: { disabled: _vm.buttonDisabled, text: "", height: "200" },
              on: {
                click: function($event) {
                  return _vm.setLicense()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("Activate")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }